import Layout from '@/layout/TheLayout.vue'
import errorsRouter from './modules/errors'
import divisionRoutes from './divisionRoutes'
import quotationRoutes from './quotationRoutes'
import bugdetRoutes from './bugdetRoutes'
import zonesRoutes from './zonesRoutes'
import businessUnitsRoutes from './businessUnitsRoutes'
import customerRoutes from './customerRoutes'
import sinisterRoutes from './sinisterRoutes'
import servicesRoutes from './servicesRoutes'
import providersRoutes from './providersRoutes'
import userRoutes from './userRoutes'
import dynamicReportsRoutes from './dynamicReportsRoutes'
import invoiceRoutes from './invoiceRoutes'
import extranetRoutes from './extranetRoutes'
import complaintsRoutes from './complaintsRoutes'
import reportRoutes from './reportRoutes'

import { uniqWith, isEqual } from 'lodash'
import { EXTRANET_GROUPS } from '@/router/utils'

const reducePermissions = (routes) => {
  // Pass Parent permissions to children
  const reducePermissions = routes.map(({ meta: metaParent = {}, children = [], ...parent }) => {
    return {
      ...parent,
      meta: metaParent,
      children: children && children.map(({ meta: metaChild = {}, ...child }) => {
        const parentExcludeGroup = metaParent.excludeGroup || []
        const childExcludeGroup = metaChild.excludeGroup || []
        const excludeGroup = uniqWith(parentExcludeGroup.concat(childExcludeGroup), isEqual)

        const ifCanComplete = { ...metaChild.ifCan, ...metaParent.ifCan }
        const ifCan = Object.keys(ifCanComplete).reduce((obj, key) => {
          const ifCanChild = metaChild.ifCan ? Array.isArray(metaChild.ifCan[key]) ? metaChild.ifCan[key] : [metaChild.ifCan[key]] : []
          const ifCanParent = metaParent.ifCan ? Array.isArray(metaParent.ifCan[key]) ? metaParent.ifCan[key] : [metaParent.ifCan[key]] : []
          const arrayPermission = [...ifCanChild, ...ifCanParent].filter(p => !!p)
          obj[key] = uniqWith(arrayPermission, isEqual)
          return obj
        }, {})

        const parentifInGroup = metaParent.ifInGroup || []
        const childifInGroup = metaChild.ifInGroup || []
        const ifInGroup = uniqWith(parentifInGroup.concat(childifInGroup), isEqual)
        return {
          ...child,
          meta: {
            ...metaChild,
            excludeGroup,
            ifCan: Object.keys(ifCan).length ? ifCan : undefined,
            ifInGroup: ifInGroup.length ? ifInGroup : undefined
          }
        }
      })
    }
  })
  return reducePermissions
}

const devRoutes = [
  // {
  //   path: '/about',
  //   name: 'about',
  //   meta: {
  //     showInSidebar: true
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '@/pages/About.vue')
  // },
  {
    path: '/',
    name: 'dev',
    component: Layout,
    meta: {
      showInSidebar: true
    },
    children: [
      {
        path: '/permissions',
        name: 'Permission',
        meta: {
          showInSidebar: true
          // ifCan: {
          //   view: ['auth', 'group']
          // },
          // ifInGroup: ['Admn', 'Admin']
        },
        component: () => import(/* webpackChunkName: "about" */ '@/pages/Permissions.vue')
      }
    ]
  }
]

/**
 * Kraken App Routes
 * @type {Array}
 */
const routes = [
  {
    path: '/',
    name: 'User',
    component: Layout,
    children: [
      {
        path: '/perfil',
        name: 'Profile',
        meta: {
          title: 'Perfil',
          showInSidebar: false
        },
        component: () => import(/* webpackChunkName: "about" */ '@/pages/Users/Profile.vue')
      },
      {
        path: '/editprofile',
        name: 'editProfile',
        meta: {
          showInSidebar: false
        },
        component: () => import(/* webpackChunkName: "about" */ '@/pages/Users/editProfile.vue')
      },
      {
        path: '/changepassword',
        name: 'ChangePassword',
        meta: {
          showInSidebar: false
        },
        component: () => import(/* webpackChunkName: "about" */ '@/pages/Users/ChangePassword.vue')
      }
    ]
  },
  ...sinisterRoutes,
  ...invoiceRoutes,
  ...servicesRoutes,
  ...divisionRoutes,
  ...quotationRoutes,
  ...bugdetRoutes,
  ...zonesRoutes,
  ...customerRoutes,
  ...businessUnitsRoutes,
  ...providersRoutes,
  ...userRoutes,
  ...reportRoutes,
  ...extranetRoutes,
  ...dynamicReportsRoutes,
  ...complaintsRoutes,
  ...(process.env.NODE_ENV !== 'production' ? devRoutes : []),
  errorsRouter,
  { path: '*', redirect: '/error/404', hidden: true }
]

export default reducePermissions(routes)
