/*
 * @vuepress
 * ---
 * title: Staff
 * ---
 */

import Transformer from '../transformer'

/**
 * @class staffTransformer
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 */

const userFullName = ({ treatment, name, first_name: firstName, last_name: lastName, mothers_last_name: secondLastName, username }) =>
  `${treatment || ''} ${firstName || name || username} ${lastName || ''} ${secondLastName || ''}`

const employeeTransformer = ({ id }) => {
  return {
    id
  }
}

class staffTransformer extends Transformer {
  /**
   * Transform fetched staff info
   *
   * @param customer The fetched staff
   *
   * @returns {Object} The transformed staff
   */

  static fetch(staff) {
    const zones = staff.zones
    return {
      'id': staff.id,
      'name': staff.name,
      'lastName': staff.last_name,
      'mothersLastName': staff.mothers_last_name,
      'title': staff.title,
      'phone': staff.phone,
      'phoneExt': staff.phone_ext,
      'cellphone': staff.cellphone,
      'email': (!!staff.email && staff.email) || null,
      'hasExtranetAccess': staff.has_extranet_access,
      'isActive': staff.is_active,
      'customer': staff.customer.id,
      'customerName': staff.customer.name,
      'typeStaff': staff.type_staff.type_staff_id,
      'typeStaffName': staff.type_staff.type_staff_name,
      'zones': zones && zones.map(z => z.id),
      'zonesNames': zones && zones.map(z => z.name),
      'boss': !!staff.boss && staff.boss.id,
      'fullnameBoss': staff.boss ? userFullName(staff.boss) : '',
      'employeesName': (!!staff.employees && !!staff.employees.length && staff.employees.map(e => `${e.title || ''} ${e.name || ''} ${e.last_name || ''} ${e.mothers_last_name || ''}`)) || [],
      'employees': staff.employees ? staff.employees.map(e => employeeTransformer(e)) : [],
      'fullName': userFullName(staff),
      'user': staff.user_extranet,
      'password': staff.user_extranet ? staff.password_extranet : null
    }
  }
  /**
   * Transform staff's info to be sent.
   *
   * @param staff The staff to be sent.
   *
   * @returns {Object} The transformed staff info
   */
  static send(staff) {
    let zones = []
    if (typeof staff.zones !== 'object') {
      zones = [staff.zones]
    } else {
      zones = [...staff.zones]
    }
    return {
      'boss': staff.boss || null,
      'cellphone': staff.cellphone,
      'customer': staff.customer,
      'email': staff.email,
      'has_extranet_access': !!staff.hasExtranetAccess,
      'id': staff.id,
      'last_name': staff.lastName,
      'mothers_last_name': staff.mothersLastName,
      'name': staff.name,
      'phone': staff.phone,
      'phone_ext': staff.phoneExt,
      'title': staff.title,
      'type_staff': staff.typeStaff,
      'zones': zones,
      'user_extranet': staff.user,
      'password_extranet': staff.password
    }
  }
}
export default staffTransformer
