import Transformer from '@/transformers/transformer'
import staffTransformer from '@/transformers/catalogs/staff'
import moment from 'moment'

/**
 * @class MatrixCustomer
 * Authorized customer
 * @author Jose Luis R
 */

export default class AuthorizationMatrix extends Transformer {
  static fetch(matrix) {
    return {
      customer: matrix.customer,
      zone: matrix.zone.slug,
      zoneName: matrix.zone.name,
      authorization: matrix.authorization.map((s) => s.id),
      budgetAmountBefore: matrix.budget_amount_before,
      budgetAmountAfter: matrix.budget_amount_after,
      repairWithoutAuthorization: matrix.repair_without_authorization,
      authorizationGNP: matrix.authorization_gnp,
      gnpMatrixStart: matrix.gnp_matrix_start,
      needAuthBefore: matrix.need_authorization_before_repair,
      needAuthAfter: matrix.need_authorization_after_repair,
      filetype: matrix.file_type,
      id: matrix.id,
      isActive: matrix.is_active,
      authorizeStaff: matrix.authorization ? staffTransformer.fetchCollection(matrix.authorization) : []
    }
  }
  static send(matrix) {
    return {
      customer: matrix.customer,
      zone: matrix.zone,
      authorization: matrix.authorization,
      need_authorization_before_repair: matrix.needAuthBefore,
      need_authorization_after_repair: matrix.needAuthAfter,
      budget_amount_before: matrix.budgetAmountBefore,
      budget_amount_after: matrix.budgetAmountAfter,
      file_type: matrix.filetype,
      repair_without_authorization: matrix.repairWithoutAuthorization,
      authorization_gnp: matrix.authorizationGNP,
      gnp_matrix_start:  matrix.authorizationGNP ? new Date(matrix.gnpMatrixStart).toISOString() : null
    }
  }
}
