import Layout from '@/layout/TheLayout.vue'

export default [
  {
    path: '/',
    name: 'Extranet',
    meta: {
      title: 'Extranet',
      showInSidebar: true,
      icon: 'mdi-earth',
      ifCan: {
        'extranets': 'Sinister/sinister'
      }
    },
    component: Layout,
    children: [
      {
        path: '/extranet/listado_siniestros/:slug',
        name: 'Extranet Sinister Listing',
        component: () => import('@/pages/Extranet/List/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Listado',
          ifInGroup: ['Admin']
        }
      },
      {
        path: '/extranet/siniestros/:slug',
        name: 'Extranet Sinister File',
        component: () => import('@/pages/Extranet/File/index'),
        props: true,
        meta: {
          title: 'Ficha de siniestro'
        }
      },
      {
        path: '/extranet/reporte_rangos',
        name: 'Range Report',
        component: () => import('@/pages/Extranet/RangeReport/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Reporte de rangos',
          ifCan: {
            'extranet_range_report': 'Sinister/sinister'
          }
        }
      },
      {
        path: '/extranet/reporte_status_siniestros',
        name: 'Status Report',
        component: () => import('@/pages/Extranet/sinistersStatusReport/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Estatus de siniestros',
          ifCan: {
            'extranets_tracking': 'Sinister/sinister'
          }
        }
      }
    ]
  }
]
