/**
 * @vuepress
 * ---
 * title: AffectedTransformer
 * ---
 */

import Transformer from '../transformer'
import providerTransform from '@/transformers/sinister/assignation'
import familyAssignTransformer from './familyAssign'
import budgetTransformer from '@/transformers/Budget/budget/'
import invoiceTransformer from './invoice'
import moment from 'moment'

const userFullName = ({ treatment, name, first_name: firstName, last_name: lastName, mothers_last_name: secondLastName, username }) =>
  `${treatment || ''} ${firstName || name || username} ${lastName || ''} ${secondLastName || ''}`

/**
 * @class AffectedTransformer
 *
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @author Jose Luis R.
 */
class AffectedTransformer extends Transformer {
  static fetch({
    id, sinister, process, name, contact, provider4attention,
    alternate_process: alternateProcess,
    contact_phones: contactPhones, second_contact_phone: secondContactPhone,
    description, damage_origin: damageorigen,
    checkin_lat: checkinLat, checkin_lon: checkinLon, meeting,
    checkin_user: checkinUser, checkin_date: checkinDate, scope_motifs: scopeMotifs,
    nul: meetingUserl, is_active: isActive, family_assig: familyAssig,
    isTAPaiment: isTAPayment, isTAProvider, slug, budget = {},
    visual_validation_date: visualValidationDate, no_provider: noProvider,
    quotation_motif: quotationMotif, authorized_authorization: authorizedQuotationDate, agreed_amount: agreedAmountDate,
    close_quotation: closedQuotationDate, complete_data: completeData, complete_stationery: completeStationery, invoice,
    visible_extranet: visibleExtranet, finish: finishedDate, unauthorized_days: unauthorizedDays
  }) {
    const providerAttention = providerTransform.fetchCollection(provider4attention)
    const checkinFullName = checkinUser && userFullName(checkinUser)
    const { abbreviation: processAbbre, slug: processSlug,
      number: processNumber, name: processName } = process

    let altProcessAbbre, altProcessSlug, alProcessNumber, altProcessName
    if (alternateProcess) {
      altProcessAbbre = alternateProcess.abbreviation
      altProcessSlug = alternateProcess.slug
      alProcessNumber = alternateProcess.number
      altProcessName = alternateProcess.name
    }
    return {
      id,
      sinisterNumber: sinister,
      slug,
      name,
      contact,
      contactPhones,
      secondContactPhone,
      description,
      damageorigen,
      checkinLat,
      checkinLon,
      invoice: invoice ? invoiceTransformer.fetch(invoice) : null,
      meeting: meeting.length ? meeting[0].meeting_date : null,
      isActive,
      isTAPayment,
      isTAProvider,
      checkinUser,
      checkinDate,
      checkinFullName,
      scopeMotifs: scopeMotifs ? scopeMotifs.motif : null,
      quotationMotif,
      meetingUserl,
      providerAttention,
      process,
      processAbbre,
      processName,
      processSlug,
      authorizedQuotationDate,
      agreedAmountDate,
      closedQuotationDate,
      completeData,
      completeStationery,
      processNumber: +processNumber,
      familyAssign: Array.isArray(familyAssig) ? familyAssignTransformer.fetchCollection(familyAssig) : [],
      alternateProcess,
      altProcessNumber: +alProcessNumber,
      altProcessAbbre,
      altProcessSlug,
      altProcessName,
      visualValidationDate,
      visibleExtranet,
      noProvider,
      budget: budget ? budgetTransformer.fetch(budget) : {},
      finished: !!finishedDate,
      finishedDate: finishedDate ? moment(finishedDate) : null,
      unauthorizedDays: unauthorizedDays || 0
    }
  }
  static send(casualty) {
    return {
      'name': casualty.name,
      'contact': casualty.contact || '',
      'contact_phones': casualty.contactPhones || '',
      'second_contact_phone': casualty.secondContactPhone || '',
      'binnacle_info': casualty.binnacleInfo || '',
      'description': casualty.description
    }
  }
  static update(casualty) {
    return {
      'name': casualty.name,
      'contact': casualty.contact || '',
      'contact_phones': casualty.contactPhones || '',
      'second_contact_phone': casualty.secondContactPhone || '',
      'binnacle_info': casualty.binnacleInfo || '',
      'description': casualty.description
    }
  }
}

export default AffectedTransformer
