
import Vue from 'vue'

const transformer = function({ widget, user }) {
  return {
    user,
    widget: {
      color: widget.agt_color,
      imagePath: widget.agt_image_path,
      message: widget.agt_message,
      name: widget.agt_name
    }
  }
}

export default message =>
  Vue.$http.post('avi_chat/login/')
    .then(response => transformer(response.data))
